.ParentConcepts{
    height:100%;
    width:100%;
    background-color:#222;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    min-height: 100vh;
    padding-top: 40px;
    box-sizing: border-box;
    
}

.ParentConcepts_Container{
    width:100%;
    height:100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    
}

.ParentConcepts_VenueImg{
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    /* filter: blur(0.5px); */

}
.ParentConcepts_VenueCard{
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 30%;
    min-height: 350px;
    max-height: 350px;
    border-radius: 10px;
    

}
.venueCard_div{
    width: 100%;
    padding: 1rem;
}
.Parent_overlay{
    position: absolute;
    top:0;
    left:0;
    min-width: 100%;
    min-height: 100%;
    background-color: rgba(10,10,10,0.7);
    z-index: 5;
    border-radius: 10px;
}
.Parent_Logo{
    width: 150px;
    
    object-Fit:contain;
    z-index: 1000;
}

@media (max-width: 768px) {
    .ParentConcepts{
        height:100%;
        width:100%;
        background-color:#222;
        display: flex;
        flex-direction: column;
        align-items:center;
        justify-content: center;
        
    }
    
    .ParentConcepts_Container{
        width:100%;
        height:100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }
    .ParentConcepts_VenueCard{
        cursor: pointer;        
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-width: 90%;
        min-height: 200px;
        max-height: 200px;
        border-radius: 10px;
        
    }
    .Parent_overlay{
        position: absolute;
        top:0;
        left:0;
        min-width: 100%;
        min-height: 100%;
        background-color: rgba(10,10,10,0.7);
        /* z-index: 5; */
        border-radius: 10px;
    }
    .venueCard_div{
        width: 90%;
        padding: 1rem;
    }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .ParentConcepts_Container{
        width:100%;
        height:100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }
    .ParentConcepts_VenueCard{
        cursor: pointer;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-width: 70%;
        min-height: 300px;
        
        border-radius: 10px;
        
    
    }
    .Parent_overlay{
        position: absolute;
        top:0;
        left:0;
        min-width: 100%;
        min-height: 300px;
        background-color: rgba(10,10,10,0.7);
        z-index: 5;
        border-radius: 10px;
    }
}