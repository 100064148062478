/* .ReservationPage{
    height:100vh;
    width:100%;
    background-color:#222;
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content: flex-start;
    overflow-y: clip;
} */
.ReservationPage {
    height:100%;
width:100%;
background-color:#222;
position: absolute;
display: flex;
flex-direction: column;
align-items:center;
justify-content: center;

}
.overlay{
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    background-color: rgba(10,10,10,0.8);
    
}

/* .ReservationPage_Container{
    position: absolute;
    left:40%;
    top:0;
    height:100vh;
    width:60%;
    background-color:#222;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: clip;
    justify-content: space-evenly;
    

} */
.ReservationPage_Container{
    position: absolute;
    width:60%;
    height:100vh;

    /* background-color:#222; */
    display: flex;
    flex-direction: column;
    align-items: center;
    
    justify-content: space-evenly;
}
.Reservation_Logo{
    width: 200px;
    height: 100px;
    object-Fit:contain;
    z-index: 1;
}

/* .Reservation_VenueImg{
        position: absolute;
        left:0;
        top:0;
        width: 40%;
        height: 100vh;
        object-Fit: "cover";
        z-Index: 1;
} */
.Reservation_VenueImg{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    object-fit: cover;
    filter: blur(0.5px);
}

.picker{
    border-radius: 20px;
    z-index: 2;
}
.listContainer{
    width:40%;
    
}
.buttonGroupDiv{
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 1rem;
}
.stages {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    min-width:40%;
    min-height: 50px;
    border : 1px solid #E1C19E;
    border-radius: 75px;
    z-index: 10;
    
}
.MuiOutlinedInput-notchedOutline{
    border-color: #E1C19E !important;
 }
/* Media query for screens smaller than 768px (phones) */
@media (max-width: 768px) {
    .ReservationPage {
        height:100%;
    width:100%;
    background-color:#222;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    
    }
    .ReservationPage_Container{
        position: unset;
        width:100%;
        background-color:#222;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .Reservation_Logo{
        width: 150px;
        height: 75px;
        
    }
    .typography {
        z-index: 2;
    }
    .overlay{
        position: absolute;
        min-width: 100%;
        min-height: 100%;
        background-color: rgba(10,10,10,0.8);
        
    }
    .Reservation_VenueImg{
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;
        object-fit: cover;
        filter: blur(0.5px);
    }
    .picker{
        transform:scale(1);
        
    }
    .listContainer{
        width:90%;
        z-index: 3;
        
    } 
    .stages {
        min-width:80%;
        min-height: 50px;
        border : 1px solid #E1C19E;
        border-radius: 75px;
        margin-bottom: 1rem !important;
        z-index: 2;
        background-color: #222;
    }
    .buttonGroupDiv{
        width: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
        margin-bottom: 1rem;
        z-index: 2;
    }
  }
  .MuiOutlinedInput-notchedOutline{
    border-color: #E1C19E !important;
 }
.input{
    min-width:50px;
    height:50px;
    border-radius: 10px;
    font-size: 25px;
    font-weight: 500;
    background-color: transparent;
    border-color: #E1C19E ;
    color:#E1C19E;
    

}

 @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .ReservationPage {
        height:100%;
    width:100%;
    background-color:#222;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    
    }
    .ReservationPage_Container{
        position: unset;
        width:100%;
        background-color:#222;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
    .Reservation_Logo{
        width: 150px;
        height: 75px;
        
    }
    .typography {
        z-index: 2;
    }
    .overlay{
        position: absolute;
        min-width: 100%;
        min-height: 100%;
        background-color: rgba(10,10,10,0.8);
        
    }
    .Reservation_VenueImg{
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;
        object-fit: cover;
        filter: blur(0.5px);
    }
    .picker{
        transform:scale(1);
        
        
    }
    .listContainer{
        width:90%;
        z-index: 3;
        
    } 
    .stages {
        min-width:60%;
        min-height: 50px;
        border : 1px solid #E1C19E;
        border-radius: 75px;
        z-index: 2;
        margin-bottom: 1rem !important;

        background-color: #222;
    }
    .buttonGroupDiv{
        width: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
        margin-bottom: 1rem;
        z-index: 2;
    }
  }
  .MuiOutlinedInput-notchedOutline{
    border-color: #E1C19E !important;
 }